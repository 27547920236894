import React from "react";
import TitleBar from "../components/TitleBar";
import NavBar from "../components/NavBar";

function Home() {
    return(
        <>
            <TitleBar/>
            <NavBar/>
        </>
    )
}

export default Home